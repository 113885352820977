// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import ProductList from './components/ProductList';
import ProductSlider from './components/ProductSlider';
import ProductHeroLeft from './components/ProductHeroLeft';
import ProductHeroRight from './components/ProductHeroRight';
import ProductHeroCenter from './components/ProductHeroCenter';
import ProductHeroOver from './components/ProductHeroOver';
import ProductMove from './components/ProductMove';
import RegisterForm from './components/RegisterForm';
import LoginForm from './components/LoginForm';
import GoogleAnalytics from './lib/GoogleAnalytics';
import Clarity from './lib/Clarity';
import Products from './components/Products';
import BuyProduct from './components/BuyProduct';
import Participa from './components/Participa';
import Carrito from './components/Carrito';
import MyAccount from './components/MyAccount';
import MisReferidos from './components/MisReferidos';
import CarritoIcono from './components/CarritoIcono';
import { CarritoProvider } from './components/context/CarritoContext.js';
import { PuntosProvider } from './components/context/PuntosContext';
import MisPuntos from './components/MisPuntos.js';
import MisPedidos from './components/MisPedidos.js';
import Sorteo from './components/Sorteo.js';
import ComoParticipar from './components/ComoParticipar.js';
import ComprarTickets from './components/ComprarTickets.js';
import MenuAdmin from './components/admin/MenuAdmin';
import VentasAdmin from './components/admin/VentasAdmin';
import TerminosYCondiciones from './components/legal/TerminosYCondiciones';
import ElMontoncito from './components/game/ElMontoncito';
import { loginUser } from './services/auth';
import CreateFolders from './components/task/CreateFolders.js';
import PoliticasPrivacidad from './components/legal/PoliticasPrivacidad';
import { loadN8nChatWidget } from './lib/n8nChatWidget';

function App() {
  const [userData, setUserData] = useState(null);
  const [totalPuntos, setTotalPuntos] = useState(0);

  const handleLogin = async (credentials) => {
    try {
      const user = await loginUser(credentials);
      setUserData(user);
    } catch (error) {
      console.error('Error de inicio de sesión:', error);
    }
  };

  const actualizarPuntos = (nuevoPuntaje) => {
    setTotalPuntos(nuevoPuntaje);
  };

  useEffect(() => {
    // Carga el widget de chat de n8n
    loadN8nChatWidget();
  }, []);

  return (
    <PuntosProvider>
      <CarritoProvider>
        <div className="App">
          <Router basename={process.env.PUBLIC_URL}>
            <Header />
            <CarritoIcono />
            <MenuAdmin />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/registrate" element={<RegisterForm />} />
              <Route path="/ingresar" element={<LoginForm onLogin={handleLogin} />} />
              <Route path="/productos" element={<Products />} />
              <Route path="/productos/detalle-producto/:sku/comprar" element={<BuyProduct />} />
              <Route path="/productos/detalle-producto/:sku/participa" element={<Participa />} />
              <Route path="/productos/detalle-producto/:sku/sorteo/:key" element={<Sorteo />} />
              <Route 
                path="/carrito" 
                element={<Carrito onCarritoVacio={() => console.log('El carrito está vacío')} />} 
              />
              <Route path="/mis-datos" element={<MyAccount />} />
              <Route path="/mis-referidos" element={<MisReferidos />} />
              <Route path="/mis-puntos" element={<MisPuntos />} />
              <Route path="/mis-pedidos" element={<MisPedidos />} />
              <Route path="/como-participar" element={<ComoParticipar />} />
              <Route path="/comprar-tickets" element={<ComprarTickets />} />
              <Route path="/admin/ventas" element={<VentasAdmin />} />
              <Route path="/legal/terminos-y-condiciones" element={<TerminosYCondiciones />} />
              <Route path="/legal/politica-de-privacidad" element={<PoliticasPrivacidad />} />
              <Route path="/task/create-folders" element={<CreateFolders />} />
              <Route 
                path="/juegos/el-montoncito" 
                element={
                  <ElMontoncito 
                    userData={userData} 
                    totalPuntos={totalPuntos} 
                    actualizarPuntos={actualizarPuntos}
                  />
                } 
              />
            </Routes>
            <Footer />
          </Router>
          <GoogleAnalytics />
          <Clarity />
        </div>
      </CarritoProvider>
    </PuntosProvider>
  );
}

function Home() {
  return (
    <main>
      <ProductSlider />
      <ProductList />
      <ProductHeroOver />
      <ProductHeroLeft />
      <ProductHeroCenter />
      <ProductMove />
      <ProductHeroRight />
    </main>
  );
}

export default App;