import React from 'react';

const PoliticasPrivacidad = () => {
  return (
    <div className="container mx-auto px-4 py-8 mt-20 text-left">
      <h1 className="text-3xl font-bold title-mm mb-8">Política de Privacidad</h1>

      <div className="space-y-20 mt-20">
        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">1. Recopilación de Datos</h2>
          <p>Recopilamos información personal que nos proporcionas directamente, como tu nombre, dirección de correo electrónico, y detalles de pago, cuando te registras o realizas una compra en nuestra plataforma.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">2. Uso de la Información</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Proveer Servicios: Utilizamos tus datos para procesar tus pedidos y ofrecerte nuestros servicios.</li>
            <li>Comunicación: Te enviamos actualizaciones sobre tu cuenta y nuestros servicios.</li>
            <li>Mejora del Servicio: Analizamos los datos para mejorar nuestra plataforma y personalizar tu experiencia.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">3. Compartición de Datos</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Proveedores de Servicios: Compartimos información con terceros que nos ayudan a operar nuestra plataforma.</li>
            <li>Requerimientos Legales: Podemos divulgar tus datos si es requerido por ley o para proteger nuestros derechos.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">4. Seguridad de la Información</h2>
          <p>Implementamos medidas de seguridad para proteger tus datos personales contra accesos no autorizados, alteraciones, divulgaciones o destrucciones.</p>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">5. Derechos del Usuario</h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Acceso y Corrección: Tienes derecho a acceder y corregir tus datos personales.</li>
            <li>Eliminación: Puedes solicitar la eliminación de tus datos personales en cualquier momento.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-2xl font-semibold color-mm mb-4">6. Cambios a esta Política</h2>
          <p>Nos reservamos el derecho de modificar esta política de privacidad en cualquier momento. Los cambios serán efectivos inmediatamente después de su publicación en nuestra plataforma.</p>
        </section>

        <p className="mt-8 p-4 bg-gray-100 rounded-lg text-gray-600 italic">Al utilizar nuestra plataforma, aceptas nuestra política de privacidad. Te recomendamos revisarla periódicamente para estar al tanto de cualquier actualización.</p>
      </div>
    </div>
  );
};

export default PoliticasPrivacidad;
