import { supabase } from '../../lib/supabaseClient';

const bucketName = 'productos';

const CreateFolders = async () => {
  for (let i = 100056; i <= 100179; i++) {
    const folderPath = `${i}/`;
    const { error } = await supabase.storage.from(bucketName).upload(`${folderPath}.keep`, '', {
      cacheControl: '3600',
      upsert: false,
    });

    if (error) {
      console.error(`Error al crear la carpeta ${folderPath}:`, error.message);
    } else {
      console.log(`Carpeta creada: ${folderPath}`);
    }
  }
};

export default CreateFolders;
